body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

button,
input {
  border: unset;
  background: unset;
}

button:focus,
input:focus {
  outline: unset;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button:not(:disabled) {
  cursor: pointer;
}
